import './App.css';
import React, { useState } from 'react';
import { SignedIn, SignedOut, SignInButton, SignUpButton, UserButton } from '@clerk/clerk-react';

type TranscriptMessage = {
  speaker: string;
  time: string;
  content: string;
}

interface AppState {
  transcript: TranscriptMessage[];
}

declare global {
  interface Window {
    whisper_tab: {
      loadWhisper: (lang: string) => Promise<void>;
      onStart(callback: (text: string) => void): void;
    }
  }
}

const App = () => {

  const downloadTranscript = () => {
    const element = document.createElement("a");

    let transcriptString = '';
    for (const message of transcript) {
      transcriptString += `[${message.speaker}]: ${message.time}\n${message.content}\n\n`
    }
    const file = new Blob([transcriptString], { type: 'text/plain' });
    element.href = URL.createObjectURL(file);
    element.download = "transcript.txt";
    document.body.appendChild(element); // Required for this to work in FireFox
    element.click();
  }

  const loadModel = async () => {
    await window.whisper_tab.loadWhisper('tiny.en')
    console.log('loadModel(): done loading model')
  }

  const startRecording = async () => {
    const iframeElement = document.getElementById('whisper_mic') as HTMLIFrameElement;

    //send message to record_tab iframe
    iframeElement.contentWindow!.postMessage('start-transcription', '*');
    window.addEventListener('message', (event) => {
      if (event.data === 'ack-start-transcription') {
        console.log('startRecording(): start message received')
      }
      const data = event.data;

      if (data.type === 'transcribed') {
        setTranscript((prevTranscript) => {
          return [
            ...prevTranscript,
            {
              speaker: 'Me',
              time: new Date().toLocaleTimeString(),
              content: data.data,
            },
          ]
        });
      }
      console.log('event', event);
    });

    await loadModel();

    window.whisper_tab.onStart((text: string) => {
      console.log('recording from whisper tab', text)
      setTranscript((prevTranscript) => {
        return [
          ...prevTranscript,
          {
            speaker: 'Other Person',
            time: new Date().toLocaleTimeString(),
            content: text,
          },
        ]
      });
    })
  }


  const [transcript, setTranscript] = useState<AppState['transcript']>([
    // Example initial data (to be removed or replaced based on your actual logic)
    {
      speaker: 'System',
      time: '1:00pm',
      content: 'Transcript will show up here',
    },
    // Add more messages here
  ]);

  // Method to render a single message
  const renderMessage = (message: TranscriptMessage, index: number) => {
    // Determine the CSS class based on the speaker
    const messageClass = message.speaker === 'Me' ? 'chat-msg me' : 'chat-msg other-person';

    return (
      <div key={index} className={messageClass}>
        <div className='chat-msg-info'>[{message.speaker}] - {message.time}</div>
        <div className='chat-msg-content'>
          {message.content}
        </div>
      </div>
    );
  };

  return (
    <div className="ambler-container">
      <header>
        <h1 className='brand'>scribe.fm</h1>
        <div className="auth-buttons">
          <SignedIn>
            <UserButton></UserButton>
          </SignedIn>
          <SignedOut>
            <SignInButton mode='modal'>
              <button className='login-button'>Sign in</button>
            </SignInButton>
            <SignUpButton mode='modal'>
              <button className='signup-button'>Sign up</button>
            </SignUpButton>
          </SignedOut>
        </div>
      </header>

      <main>
        <div className='hero'>
          <h2>Get notes for any meeting.</h2>
          <h3>No installs. Fully private. Totally free.</h3>
          <button className='record-button' onClick={startRecording}>Record notes</button>
        </div>

        <div className="chat">
          {transcript.map(renderMessage)}
        </div>

        <div className="actions">
          <button className='download-button' onClick={downloadTranscript}>Download transcript</button>
          <button className='summarize-button'>Summarize</button>
        </div>
      </main>
    </div>
  );
}

export default App;
